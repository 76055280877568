
import {
  computed, defineComponent, ref, watch, PropType, Ref,
} from '@vue/composition-api';
import FCImage from '~/components/FCImage.vue';
import { IAccessoryProperties, ISelectedUpsellProperties } from '~/types/fightcamp';

// TODO simplfy types here and the data format of glove upsells and other upsells
interface IVariant {
    id: string;
    name: string;
    description?: string;
    price?: number;
    img?: {
      src: string;
      alt: string;
    };
}

interface IUpsellsProperties extends IAccessoryProperties {
  variants: (IAccessoryProperties['variants'][number] & IVariant)[];
}

export default defineComponent({
  name: 'Upsell',
  emits: ['upsellItemSelected'],
  components: {
    FCImage,
  },
  props: {
    upsellProduct: { // TODO type and set defaults and combine with gloveupsells
      type: Object as PropType<IUpsellsProperties>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const hasVariants = computed(() => props.upsellProduct?.variants?.length > 1);
    const productId = computed(() => props.upsellProduct.id);

    const isChecked = ref(false);
    function toggleCheck() {
      isChecked.value = !isChecked.value;

      if (!hasVariants.value) { // if no variants remove or add
        if (isChecked.value) {
          addUpsell(props.upsellProduct);
        } else {
          removeUpsell(productId.value);
        }
      }
    }

    // @ts-ignore
    const { $options } = useNuxtApp();
    function formatPrice(price: number) {
      return $options.filters.currency2(price);
    }
    function getPriceRange(upsell: any) {
      const minPrice = upsell.variants.reduce((acc: number, variant: any) => {
        if (variant.price < acc) {
          return variant.price;
        }
        return acc;
      }, upsell.variants[0].price);
      const maxPrice = upsell.variants.reduce((acc: number, variant: any) => {
        if (variant.price > acc) {
          return variant.price;
        }
        return acc;
      }, upsell.variants[0].price);

      return `${formatPrice(minPrice)} - ${formatPrice(maxPrice)}`;
    }

    const selectedItem: Ref<ISelectedUpsellProperties[]> = ref([]);
    function findQuantity(itemId : string) {
      const filteredItems = selectedItem.value?.filter((item: IAccessoryProperties | IVariant) => item.id === itemId);
      return filteredItems.length;
    }
    function addUpsell(item: IUpsellsProperties | IAccessoryProperties) {
      // need to set variantId as selectedId so in ProductAbovetheFold we can add the selected variant id to cart
      selectedItem.value.push({ ...item, selectedId: item?.variants?.[0]?.id ?? item?.id });
    }
    function removeUpsell(itemId: string) {
      const index = selectedItem.value.findIndex((item) => item.id === itemId);
      selectedItem.value.splice(index, 1);
    }

    watch(selectedItem, (value) => {
      emit('upsellItemSelected', { items: value, productId: productId.value });
    });

    return {
      toggleCheck,
      getPriceRange,
      isChecked,
      findQuantity,
      addUpsell,
      removeUpsell,
      selectedItem,
      hasVariants,
    };
  },
});
